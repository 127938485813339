import React, { useEffect, useState } from "react"
import { Marker, TileLayer, Popup, MapContainer } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import image1 from "../../../assets/images/Vector07.svg"
import image2 from "../../../assets/images/Vector08.svg"
import image3 from "../../../assets/images/Vector09.svg"
import image4 from "../../../assets/images/Vector10.svg"
import image5 from "../../../assets/images/Vector11.svg"
import image6 from "../../../assets/images/Vector12.svg"
import image7 from "../../../assets/images/Vector13.svg"
import image8 from "../../../assets/images/Vector14.svg"
import image9 from "../../../assets/images/Vector15.svg"
import image10 from "../../../assets/images/Vector16.svg"
import { Link, useParams } from "react-router-dom"
import { getNameFromLocale } from "../../../helper"
import { useSelector } from "react-redux"
import i18n from "../../../services/i18n"

import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
const imageArrays = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
]

const PropertyMap = ({ zoom, selectedProperty }) => {
  const { description, id, name, location, price, facilities, totalPrice, arrival, departure } = selectedProperty
  const { latitude: lat, longitude: long } = location
  const allFacilities = useSelector((state) => state.core.facilities)
  const [totalNights, setTotalNights] = useState(0)

  const {
    arrivalDate,
    departureDate,
  } = useSelector((state) => state.search)

  const getTotalNights = (start, end) => {
    const arrival = new Date(start)
    const departure = new Date(end)
    const diff = departure - arrival
    const totalNights = diff / (24 * 60 * 60 * 1000)
    return parseInt(totalNights)
  }
  // Effect hook to update totalNights whenever arrivalDate or departureDate change
   useEffect(() => {
     setTotalNights(getTotalNights(arrivalDate, departureDate))
   }, [arrivalDate, departureDate])

  const [latitude, setLatitude] = useState(48.7046684)
  const [longitude, setLongitude] = useState(21.2575543)
  const markerRef = React.useRef(null)

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup()
    }
    setLatitude(lat)
    setLongitude(long)
  }, [lat, long, markerRef])
  const params = useParams()

  // useEffect(() => {
  //   setLatitude(51.505)
  //   setLongitude(-0.09)
  // }, [])

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{ height: "100%", width: "100%", borderRadius: "12px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[latitude, longitude]} ref={markerRef}>

        <Tooltip id="my-tooltip" />

        <Popup
          autoPan={true} // Automatically pans the map to keep the popup in view
          keepInView={true} // Ensures the popup stays in view when the user pans or zooms
        >
          <div className="customize-tooltip">
            <h1>{name}</h1>
            <p>
              Apartmán sa nachádza blízko centra, k dispozícii sú 2 parkovacie
              miesta
            </p>
            <strong>Vybavenie apartmánu</strong>

            <ul className="featureIcnList listUnstyled">

            {facilities.length > 0 && allFacilities
                ?.filter((facility) => () => {facilities?.include(facility.id)})
              .map((facility) => (
                <li key={facility.id}>
                  <span className="Icn">
                    <img
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={getNameFromLocale(facility.name, i18n.resolvedLanguage)}
                      data-tooltip-place="top"
                      alt={getNameFromLocale(facility.name, i18n.resolvedLanguage)}
                      src={`https://admin.rentalls.com/uploads/${facility.icon}`}
                    />
                  </span>
                </li>
              ))}
             
            </ul>
            <div className="priceBtnWrap">
              <span className="priceTag">
                {arrival} {departure}
                <b>{totalPrice} € /</b>{totalNights} noc
              </span>
              <Link to={`/${params.tenant}/book/${id}`} className="btnPrimary">
                Rezervovať
              </Link>
            </div>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default PropertyMap
