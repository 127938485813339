import React, { useRef } from "react"
import {
  arrivalDropdownHandler,
  closeAllDropdown,
  departureDropdownHandler,
  setArrivalDate,
  setDepartureDate,
  setIsArrival,
  setIsDeparture,
  setIsMobileOpen,
} from "../../../_actions/searchActions"
import Dropdown from "./Dropdown"
import searchIcon from "../../../assets/images/icnSearch.svg"

import { useDispatch, useSelector } from "react-redux"
import CityDropdown from "./CityDropdown"
import CalendarDropdown from "./CalendarDropdown"
import { fetchProperties } from "../../../_actions/propertyActions"
import { useParams } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from "react-i18next"

const SearchBar = () => {
  const params = useParams()
  const properties = useSelector((state) => state.properties.properties)
  const dispatch = useDispatch()
  const {
    isArrival,
    isDeparture,
    arrivalDate,
    departureDate,
    errorDate,
    pageNumber,
    selectedCity,
    persons,
    types,
  } = useSelector((state) => state.search)
  const lngs = {
    en: { nativeName: "English" },
    sk: { nativeName: "Slovak" },
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    dispatch(closeAllDropdown())

    const location = selectedCity
    const startDate = arrivalDate
    const endDate = departureDate
    const adults = persons.adults
    const children = persons.children
    const pets = persons.pets
    const type = types
      .filter((tag) => tag.isSelected)
      .map((t) => t.name)
      .join(",")

    const options = {
      location,
      startDate,
      endDate,
      adults,
      children,
      pets,
      type,
    }
    dispatch(fetchProperties(pageNumber, options, params.tenant))
  }
  const departureDateInput = useRef(null)
  const { t, i18n } = useTranslation()

  return (
    <>
      <form className="languageForm desktop">
        <select
          value={i18n.resolvedLanguage}
          onChange={(event) => i18n.changeLanguage(event.target.value)}
        >
          {Object.keys(lngs).map((lng) => (
            <option key={lng} value={lng}>
              {lng}
            </option>
          ))}
        </select>
      </form>
      <div className="searchBarMobileWrap">
        <form className="tpSearchBar">
          <div className="formBody">
            <div className="topBarWrapper">
              <span
                className="filterCloser"
                onClick={() => dispatch(setIsMobileOpen(false))}
              >
                <i className="fa-solid fa-angle-left fa-2xs"></i>
                {t("back")}
              </span>
              <form className=" desktop">
                <select
                  value={i18n.resolvedLanguage}
                  onChange={(event) => i18n.changeLanguage(event.target.value)}
                >
                  {Object.keys(lngs).map((lng) => (
                    <option key={lng} value={lng}>
                      {lngs[lng].nativeName}
                    </option>
                  ))}
                </select>
              </form>
            </div>

            <div className="innerBodyWrap">
              <CityDropdown forView={"cityInputWrap"} />
              <CalendarDropdown
                forView={"mobile"}
                id={"arrivalMobile"}
                placeholder={t("arrival")}
                isActive={isArrival}
                handleClick={() => {
                  dispatch(arrivalDropdownHandler())
                }}
                value={arrivalDate}
                onChange={(date) => dispatch(setArrivalDate(date))}
                //   isError={errorDate}
              />

              <CalendarDropdown
                forView={"mobile"}
                id={"departureMobile"}
                placeholder={t("departure")}
                isActive={isDeparture}
                handleClick={() => dispatch(departureDropdownHandler())}
                onChange={(date) => dispatch(setDepartureDate(date))}
                value={departureDate}
                isError={errorDate}
              />
              <Dropdown forView={"mobile"} />
            </div>
          </div>
          <div className="formFooter">
            <div className="btnWrap">
              <button
                type="button"
                onClick={submitHandler}
                className="btn btnPrimary btnSubmit "
              >
                {t("search")}
              </button>
            </div>
            <div className="inputBtnWrap resultWrap dNone">
              <div className="resultTitle">
                <span className="resultCountNumber">27</span>
                <span className="resultCountText">{t("results_found")}</span>
              </div>
              <button
                type="button"
                className="btnPrimary"
                onClick={submitHandler}
              >
                {t("search")}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="searchBarDesktpoWrap">
        <form className="tpSearchBar">
          <div className="colsHolder">
            <div className="chCol chCol2">
              <CityDropdown forView={"dropdownWrap"} />
            </div>
            <div className="chCol chCol2">
              <div className="rental-dp-calender-main">
                <DatePicker
                  className="formControl"
                  // onCalendarOpen={() => dispatch(setIsArrival(true))}
                  // onCalendarClose={() => dispatch(setIsArrival(false))}
                  // onClickOutside={() => dispatch(setIsArrival(false))}
                  selected={arrivalDate}
                  onChange={(date) => {
                    dispatch(setArrivalDate(date))
                    departureDateInput.current.setFocus()
                    // dispatch(setIsArrival(false))
                  }}
                  selectsStart
                  startDate={arrivalDate}
                  endDate={departureDate}
                  monthsShown={2}
                  // multiple={false}
                  // defaultChecked={false}
                  // numberOfMonths={2}
                  // disableMonthPicker
                  // disableYearPicker
                  // minDate={new Date()}
                  // value={arrivalDate}
                />
              </div>
            </div>
            <div className="chCol chCol2">
              <div className="rental-dp-calender-main">
                <DatePicker
                  className="formControl"
                  // onCalendarOpen={() => dispatch(setIsDeparture(true))}
                  // onCalendarClose={() => dispatch(setIsDeparture(false))}
                  // onClickOutside={() => dispatch(setIsDeparture(false))}
                  monthsShown={2}
                  selected={departureDate}
                  onChange={(date) => {
                    dispatch(setDepartureDate(date))
                    // dispatch(setIsDeparture(false))
                  }}
                  selectsEnd
                  startDate={arrivalDate}
                  endDate={departureDate}
                  minDate={arrivalDate}
                  ref={departureDateInput}
                  // numberOfMonths={2}
                  // disableMonthPicker
                  // disableYearPicker
                  // minDate={arrivalDate}
                  // value={departureDate}
                />
              </div>
            </div>

            <div className="chCol chCol2">
              <Dropdown forView={"deskTop"} />
            </div>
            <div className="chCol chCol2">
              <div className="formGroup resultWrap">
                <div className="resultTitle">
                  <span className="resultCountNumber">{properties.total}</span>
                  <span className="resultCountText">{t("results_found")}</span>
                </div>
              </div>
            </div>
            <div className="chCol chCol2 ">
              <div className="submitBtn" onClick={submitHandler}>
                <a href="#" className="btnPrimary">
                  {t("search")}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="searchInputWrap">
        <input
          onClick={() => dispatch(setIsMobileOpen(true))}
          type="text"
          className="fromControl filterOpener active filter-open"
          placeholder={t("find_acc")}
        />
        <span className="icn">
          <img src={searchIcon} alt="search icn" />
        </span>
      </div>
    </>
  )
}

export default SearchBar
