export const getTenantDomain = (tenant: string) => {
  // if (process.env.NODE_ENV === 'production') {
  return `https://${tenant}.rentalls.com`
  // }
  return `http://${tenant}.rentalls.test`;
}


export const getNameFromLocale = (json: any, locale: any) => {
  try {
    const strings = JSON.parse(json)
    if (strings[locale]) {
      return strings[locale]
    }
    return strings[Object.keys(strings)[0]]
  } catch (err) {
    return json
  }
}

export const formatMySQLDate = (date: Date) => {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, "0") // months are 0-based in JS
  const day = date.getDate().toString().padStart(2, "0")
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")

  return (
    year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  )
}
