import React, { useCallback, useEffect, useRef, useState } from "react"

import unionIcon from "../../../assets/images/Union.svg"
import unionIcon01 from "../../../assets/images/union01.svg"
import image01 from "../../../assets/images/imgdev02.jpg"
import SearchBar from "../HomePage/SearchBar"
import { useDispatch, useSelector } from "react-redux"
import { fetchRequisites } from "../../../_actions/coreActions"
import { useParams } from "react-router-dom"
import moment from "moment"
import { submitDetailsForm } from "../../../api"

import { Elements, CardNumberElement } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "../../partials/CheckoutForm"
import Spinner from "../../partials/Spinner"
import SuccessPage from "./SuccessPage"
import { getTenantDomain } from "../../../helper"
import { useTranslation } from "react-i18next"

const FormPage = () => {
  const [stripe, setStripe] = useState(null)
  const [error, setError] = useState(null)
  const [formError, setFormError] = useState({})
  const [elements, setElements] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isBooked, setIsBooked] = useState(false)
  const params = useParams()
  const [isChecked, setChecked] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    async function fetchKeyAndInitializeStripe() {
      try {
        const res = await fetch(
          `${getTenantDomain(params.tenant)}/api/get-stripe-key`
        )
        if (!res.ok) {
          throw new Error("Failed to fetch Stripe key")
        }
        const data = await res.json()
        const stripe = await loadStripe(data.key)
        setStripe(stripe)
      } catch (err) {
        setError(err.message)
      }
    }

    fetchKeyAndInitializeStripe()
  }, [])

  const upsells = useSelector((state) => state.core.upsells)

  const dispatch = useDispatch()
  const { arrivalDate, departureDate, persons, selectedProperty } = useSelector(
    (state) => state.search
  )

  useEffect(() => {
    dispatch(fetchRequisites(params.tenant))
  }, [dispatch])

  const [total, setTotal] = useState(0)
  const [upsellValues, setUpsellValues] = useState({})
  const [userDetails, setUserDetails] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  })
  const { name, lastName, email, phone, message } = userDetails

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const increaseUpsellValue = (ups) => {
    if (!upsellValues) return
    setUpsellValues((prevState) => ({
      ...prevState,
      [ups.id]: {
        ...prevState[ups.id],
        value:
          (prevState[ups.id] && prevState[ups.id]["value"]
            ? prevState[ups.id]["value"]
            : 0) + 1,
        icon: ups.icon,
        price: ups.price,
        name: ups.code,
        type: ups.type,
        id: ups.id,
      },
    }))
  }

  const decreaseUpsellValue = (ups) => {
    if (!upsellValues) return
    setUpsellValues((prevState) => ({
      ...prevState,
      [ups.id]: {
        ...prevState[ups.id],
        value:
          (prevState[ups.id] && prevState[ups.id]["value"]
            ? prevState[ups.id]["value"]
            : 0) - 1,
        icon: ups.icon,
        price: ups.price,
        name: ups.code,
        type: ups.type,
        id: ups.id,
      },
    }))
  }
  const totalDays = useCallback(() => {
    var start = moment(new Date(arrivalDate), "YYYY-MM-DD")
    var end = moment(new Date(departureDate), "YYYY-MM-DD")
    return Math.abs(moment.duration(start.diff(end)).asDays())
  }, [arrivalDate, departureDate])
  const totalHours = useCallback(() => {
    var start = moment(new Date(arrivalDate), "YYYY-MM-DD 02:00:ss")
    var end = moment(new Date(departureDate), "YYYY-MM-DD 11:00:ss")
    return Math.abs(moment.duration(start.diff(end)).asHours())
  }, [arrivalDate, departureDate])

  const totalUpsell = useCallback(
    (item) => {
      let total = 0
      if (item.value > 0) {
        let item_price = 0
        switch (item.type) {
          case "stay":
            item_price = item.price
            break
          case "hour":
            item_price = item.price * totalHours()
            break
          case "night_person":
            item_price = item.price * persons.adults * totalDays()
            break
          default:
            item_price = item.price * totalDays()
            break
        }
        total += item_price * item.value
      }
      return total
    },
    [persons.adults, totalDays, totalHours]
  )

  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const handleSubmit = async (stripe, elements) => {
    setIsLoading(true)
    if (paymentMethodId) {
      submitDetailsForm(
        upsellValues,
        params.id,
        userDetails,
        paymentMethodId,
        { arrivalDate, departureDate, persons },
        params.tenant
      )
        .then((response) => {
          setIsLoading(false)
          setIsBooked(true)
          console.log(response)
        })
        .catch((error) => {
          console.log("this is error:", error.response.data)
          setFormError(error.response.data.errors)
          setIsLoading(false)
          setPaymentMethodId(null)
        })
      return
    }
    if (!stripe || !elements) {
      return
    }

    const cardNumberElement = elements.getElement(CardNumberElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name: document.getElementById("inputCardholderName").value,
      },
    })

    if (error) {
      console.log("[error]", error)
      setIsLoading(false)
      setError(error.message)
    } else {
      console.log("[PaymentMethod]", paymentMethod)
      setPaymentMethodId(paymentMethod.id)
      submitDetailsForm(
        upsellValues,
        params.id,
        userDetails,
        paymentMethod.id,
        { arrivalDate, departureDate, persons },
        params.tenant
      )
        .then((response) => {
          setIsLoading(false)
          setIsBooked(true)
          console.log(response)
        })
        .catch((error) => {
          console.log("this is error:", error.response.data)
          setFormError(error.response.data.errors)
          setIsLoading(false)
          setPaymentMethodId(null)
        })
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    if (!isChecked) {
      alert(t("accept"))
      return
    }
    if (formRef && formRef.current) {
      handleSubmit(stripe, elements)
    }
  }

  useEffect(() => {
    let total = 0
    Object.values(upsellValues).forEach((upsell) => {
      total += totalUpsell(upsell)
    })
    total += selectedProperty?.totalPrice ?? 0
    setTotal(total.toFixed(2))
  }, [total, totalUpsell, upsellValues, selectedProperty])

  const formRef = useRef()

  if (!stripe) {
    return <p>{t("loading")}</p>
  }

  if (isBooked) {
    return <SuccessPage />
  }

  return (
    <main>
      <section className="reservationSection">
        <div className="container">
          <SearchBar />
          <div className="appartBookingDetailWrap">
            <div className="colsHolder">
              <div className="chCol chCol8">
                <div className="custBookDetailsWrap">
                  <header className="headingHead">
                    <h2>{t("contact_details")}</h2>
                  </header>
                  <form className="bookingForm">
                    <div className="colsHolder">
                      <div className="chCol chCol6">
                        <div className="formGroup">
                          <input
                            onChange={handleChange}
                            name="name"
                            value={name}
                            type="text"
                            className="formControl"
                            placeholder={t("placeholder_name")}
                          />
                          {formError["userDetails.name"] && (
                            <p className="error-message">
                              {formError["userDetails.name"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="chCol chCol6">
                        <div className="formGroup">
                          <input
                            onChange={handleChange}
                            value={lastName}
                            name="lastName"
                            type="text"
                            className="formControl"
                            placeholder={t("placeholder_last_name")}
                          />
                          {formError["userDetails.lastName"] && (
                            <p className="error-message">
                              {formError["userDetails.lastName"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="chCol chCol6">
                        <div className="formGroup">
                          <input
                            onChange={handleChange}
                            value={email}
                            name="email"
                            type="text"
                            className="formControl"
                            placeholder={t("placeholder_email")}
                          />
                          {formError["userDetails.email"] && (
                            <p className="error-message">
                              {formError["userDetails.email"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="chCol chCol6">
                        <div className="formGroup">
                          <input
                            onChange={handleChange}
                            value={phone}
                            name="phone"
                            type="text"
                            className="formControl"
                            placeholder={t("placeholder_phone")}
                          />
                          {formError["userDetails.phone"] && (
                            <p className="error-message">
                              {formError["userDetails.phone"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="chCol chCol12">
                        <div className="formGroup mbZero">
                          <textarea
                            onChange={handleChange}
                            value={message}
                            name="message"
                            type="text"
                            className="formControl"
                            placeholder={t("placeholder_message")}
                          ></textarea>
                          {formError["userDetails.message"] && (
                            <p className="error-message">
                              {formError["userDetails.message"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="roomAcceriesWrap">
                    <header className="headingHead">
                      <h2>{t("additional_item")}</h2>
                    </header>
                    <ul className="asseriesDetails listUnstyled">
                      {upsells
                        .filter((ups) => {
                          if (ups && ups.excluded_properties) {
                            if (typeof ups.excluded_properties === "string") {
                              return !ups.excluded_properties
                                .split(",")
                                .includes(params.id)
                            }
                            return true
                          }
                          return true
                        })
                        .map((ups, i) => (
                          <li key={i}>
                            <div className="asessDescrWrap">
                              <div className="assesTextDescr">
                                <span className="icnAsses">
                                  <img src={unionIcon} alt="imageDescription" />
                                </span>
                                <div className="textWrap">
                                  <h3>{ups.code}</h3>
                                  <p>
                                    <strong>{ups.price} € / </strong>za každú
                                    začatú hodinu
                                  </p>
                                </div>
                              </div>
                              <div className="quantity">
                                <input
                                  name={`val-${i}`}
                                  type="number"
                                  min="1"
                                  value={upsellValues[ups.id]?.value || 0}
                                />
                                <div className="quantity-nav">
                                  <div
                                    className="quantity-button quantity-plus d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      if (
                                        upsellValues[ups.id]?.value >=
                                        (ups.is_multi_quantity === 1
                                          ? ups.quantity_limit
                                          : 1)
                                      ) {
                                        return
                                      } else {
                                        increaseUpsellValue(ups)
                                      }
                                    }}
                                  >
                                    <i
                                      className="fas fa-plus"
                                      aria-hidden="true"
                                    >
                                      <span className="sr-only">icon</span>
                                    </i>
                                  </div>
                                  <div
                                    className="quantity-button quantity-minus d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      if (upsellValues[ups.id]?.value > 0) {
                                        decreaseUpsellValue(ups)
                                      } else {
                                        return
                                      }
                                    }}
                                  >
                                    <i
                                      className="fas fa-minus"
                                      aria-hidden="true"
                                    >
                                      <span className="sr-only">icon</span>
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <header className="headingHead">
                    <h2>{t("payment")}</h2>
                  </header>

                  <Elements stripe={stripe}>
                    <CheckoutForm
                      formRef={formRef}
                      setElements={setElements}
                      setError={setError}
                      setStripe={setStripe}
                      error={error}
                      handleSubmit={handleSubmit}
                      isChecked={isChecked}
                      setChecked={setChecked}
                    />
                  </Elements>
                </div>
              </div>
              <div className="chCol chCol4">
                <div className="appartSpecsWrap">
                  <div className="imgHolder">
                    <img src={image01} alt="imageDescription" />
                  </div>
                  <div className="appartDescrTextWrap">
                    <header className="appartHeader">
                      <h3>{t("postal_apt")}</h3>
                    </header>
                    <ul className="listUnstyled dateTimeInfo">
                      <li>
                        <h4 className="text-upper">{t("arrival")}</h4>
                        <p>
                          {moment(new Date(arrivalDate)).format("DD-MM-YYYY")}
                        </p>
                      </li>
                      <li>
                        <h4 className="text-upper">{t("departure")}</h4>
                        <p>
                          {moment(new Date(departureDate)).format("DD-MM-YYYY")}
                        </p>
                      </li>
                      <li className="icnWrapList">
                        <span className="icnAsses">
                          <img src={unionIcon01} alt="imageDescription" />
                        </span>
                        <p>
                          {persons.adults} {t("adult")}, {persons.children}{" "}
                          {t("children")}
                        </p>
                      </li>
                    </ul>
                    <header className="appartHeader">
                      <h4>{t("selected_add")}</h4>
                    </header>
                    <ul className="listUnstyled appartQunatList">
                      {Object.entries(upsellValues).map(([id, upsell]) =>
                        upsell.value === 0 ? null : (
                          <li>
                            <div className="icnDescrWrap">
                              <span className="icnAsses">
                                <img src={unionIcon01} alt="imageDescription" />
                              </span>
                              <p>
                                {upsell.name} {Number(upsell.value)}x
                              </p>
                            </div>
                            <strong className="appartPrice">
                              {totalUpsell(upsell)} €
                            </strong>
                          </li>
                        )
                      )}
                    </ul>
                    <div className="totalRow">
                      <div className="icnDescrWrap">
                        <p>{t("total")}</p>
                      </div>
                      <strong className="appartPrice">{total} €</strong>
                    </div>
                    <div className="submitBtn">
                      <button
                        disabled={isLoading}
                        className="btnPrimary"
                        onClick={submitHandler}
                      >
                        {isLoading && <Spinner />}
                        {t("confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default FormPage
