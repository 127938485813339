import React, { useEffect } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa" // Import Font Awesome icons
import dropdownIcon from "../../../assets/images/dropdown.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  addChildrenAge,
  decreaseAdults,
  decreasePets,
  increaseAdults,
  increaseChildren,
  increasePets,
  openPersonDropdownHandler,
  removeChildrenAge,
  setIsPersonDropdownActive,
} from "../../../_actions/searchActions"
import { useTranslation } from "react-i18next"

const QuantityNav = ({
  min,
  value,
  setValueForIncrease,
  setValueForDecrease,
}) => (
  <div className="quantity-nav">
    <div
      className="quantity-button quantity-plus d-flex align-items-center justify-content-center"
      onClick={() => {
        setValueForIncrease()
      }}
    >
      <FaChevronRight aria-hidden="true" />
      <span className="sr-only">Increase quantity</span>
    </div>
    <div
      className="quantity-button quantity-minus d-flex align-items-center justify-content-center"
      onClick={() => {
        if (value > min) {
          setValueForDecrease()
        }
      }}
    >
      <FaChevronLeft aria-hidden="true" />
      <span className="sr-only">Decrease quantity</span>
    </div>
  </div>
)

function LandingPage({ forView, handleClick }) {
  const { persons, isPersonDropdownActive: dropdownActive } = useSelector(
    (state) => state.search
  )
  const dispatch = useDispatch()
  function handleChange(event, index) {
    const { value } = event.target
    dispatch(addChildrenAge(index, value))
  }

  useEffect(() => {
    if (dropdownActive) {
      document.body.classList.add("dropdown-open")
    } else {
      document.body.classList.remove("dropdown-open")
    }
    return () => {
      document.body.classList.remove("dropdown-open")
    }
  }, [dropdownActive])
  const { t } = useTranslation()

  return (
    <div
      className={`formGroup dropdownWrap mb0 ${
        dropdownActive ? "dropdown-active" : ""
      }`}
    >
      <span
        className="dropdownButton"
        id="dropdownButton"
        onClick={() => dispatch(openPersonDropdownHandler())}
      >
        {t("number_of_person")}
      </span>

      <div
        className="customDropdown numberOfPerson"
        style={{ zIndex: "10000" }}
      >
        {forView === "mobile" && (
          <h2 className="blockLable">
            {t("number_of_person")}
            <span
              className="icnClosed"
              onClick={() => dispatch(setIsPersonDropdownActive(false))}
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
          </h2>
        )}
        <div className="formRow">
          <div className="labelWrap">
            <span className="label">{t("adult")}</span>
            <span className="description">{t("atleast_age")}</span>
          </div>
          <div className="inputWrap">
            <div className="quantity">
              <input type="number" min="1" value={persons.adults} />
              <QuantityNav
                min={1}
                value={persons.adults}
                setValueForIncrease={() => {
                  dispatch(increaseAdults())
                }}
                setValueForDecrease={() => {
                  dispatch(decreaseAdults())
                }}
              />
            </div>
          </div>
        </div>
        <div className="formRow">
          <div className="labelWrap">
            <span className="label">{t("children")}</span>
            <span className="description">{t("under_age")}</span>
          </div>
          <div className="inputWrap">
            <div className="quantity">
              <input type="number" min="0" value={persons.children} />
              <QuantityNav
                min={0}
                value={persons.children}
                setValueForIncrease={() => {
                  dispatch(increaseChildren())
                }}
                setValueForDecrease={() => {
                  dispatch(removeChildrenAge())
                }}
              />
            </div>
          </div>
        </div>
        {Array?.from({ length: persons.children }, (_, index) => (
          <div className="formRow" key={index}>
            <div className="labelWrap">
              <span className="label">{t("children_age")}</span>
              <span className="description">{t("enter_age")}</span>
            </div>
            <div className="inputWrap">
              <input
                value={persons.ageOfChildren[index] || ""}
                onChange={(event) => handleChange(event, index)}
                type="number"
                className="formControl"
                placeholder={t("age")}
                name={`age-${index}`}
              />
            </div>
          </div>
        ))}

        <div className="formRow">
          <div className="labelWrap">
            <span className="label">{t("pet")}</span>
          </div>
          <div className="inputWrap">
            <div className="quantity">
              <input type="number" min="0" value={persons.pets} />
              <QuantityNav
                min={0}
                value={persons.pets}
                setValueForDecrease={() => {
                  dispatch(decreasePets())
                }}
                setValueForIncrease={() => {
                  dispatch(increasePets())
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <img src={dropdownIcon} className="dropdownArrow" alt="dropdown" />
    </div>
  )
}

export default LandingPage
