import React, { useEffect } from "react"
import icnPinIcon from "../../../assets/images/icnPin.svg"
import searchIcon from "../../../assets/images/icnSearch.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  openCityDropdownHandler,
  setIsCityDropdown,
  setSelectedCity,
} from "../../../_actions/searchActions"
import { useTranslation } from "react-i18next"

const CityDropdown = ({ forView }) => {
  const dispatch = useDispatch()
  const cities = useSelector((state) => state.core.cities)

  const { isCityDropdownActive: dropdownActive, selectedCity } = useSelector(
    (state) => state.search
  )
  useEffect(() => {
    if (dropdownActive) {
      document.body.classList.add("dropdown-open")
    } else {
      document.body.classList.remove("dropdown-open")
    }
    return () => {
      document.body.classList.remove("dropdown-open")
    }
  }, [dropdownActive])

  const { t } = useTranslation()

  return (
    <div
      className={`formGroup ${forView} ${
        dropdownActive ? "dropdown-active" : ""
      }`}
    >
      {forView === "cityInputWrap" && (
        <h2 className="cityLable">
          {t("city")}
          <span
            className="icnClose"
            onClick={() => dispatch(setIsCityDropdown(false))}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </h2>
      )}
      <div className="inputWrap">
        <input
          // onBlur={() => {
          //   setDropdownActive(false)
          // }}
          value={selectedCity}
          onChange={(e) => dispatch(setSelectedCity(e.target.value))}
          onClick={() => dispatch(openCityDropdownHandler())}
          className="formControl cityInput"
          type="search"
          placeholder={t("city")}
        />
        <span className="icn">
          <img src={searchIcon} alt="search icn" />
        </span>
      </div>
      <div className="customDropdown cityDropdown">
        <ul className="listUnstyled cityResultList">
          {cities?.map((city) => (
            <li
              key={city}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(setSelectedCity(city))
                dispatch(setIsCityDropdown(false))
              }}
            >
              <a href="#">
                <span className="icnPin">
                  <img src={icnPinIcon} alt="icn pin" />
                </span>
                {city}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CityDropdown
