import i18n from "i18next"
import { initReactI18next } from "react-i18next"


const initializeI18n = async () => {
  // Fetch the translations from the API
  const translations = window.i18n
  if (translations) {
    i18n.use(initReactI18next).init({
      resources: translations, // Use the fetched translations
      lng: "en",
      fallbackLng: Object.keys(translations),
      interpolation: {
        escapeValue: false,
      },
    });
  } else {
    // Handle error if the translations could not be fetched
    console.error('Could not fetch translations');
  }
};
// Call the initialize function
initializeI18n();
export default i18n
