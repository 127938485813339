import React, { useState, useEffect, useCallback } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import { getTenantDomain } from '../../../helper';

export default function PropertyImage({ images, property, tenant }) {
    const imagePath = `${getTenantDomain(tenant)}/tenancy/assets/`;

    const [mainImage, setMainImage] = useState(
        images.length > 0 
            ? `${imagePath}properties/gallery/${images[0].filename}` 
            : property.featured_image 
                ? `${imagePath}${property.featured_image}` 
                : 'https://via.placeholder.com/150'
    );
    const [hoverImageIndex, setHoverImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    // Change the displayed image on hover
    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setHoverImageIndex((hoverImageIndex + 1) % images.length);
                setMainImage(`${imagePath}properties/gallery/${images[hoverImageIndex].filename}`);
            }, 1000);  // change image every second
            return () => clearInterval(interval);
        }
    }, [hoverImageIndex, images]);

    const openLightbox = useCallback(() => {
        if(images.length === 0) return;
        setCurrentImage(hoverImageIndex);
        setViewerIsOpen(true);
    }, [hoverImageIndex]);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const imageSet = images.map((img) => ({
        source: `${imagePath}properties/gallery/${img.filename}`,
        caption: "-",
    }));

    return (
        <div>
            <div 
                style={{ 
                    height: '177px', 
                    width: '169px', 
                    backgroundImage: `url(${mainImage})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center' 
                }}
                onClick={openLightbox} 
            />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={imageSet}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}
