import axios from "axios"
import moment from "moment";
import { getTenantDomain } from "../helper";



export const submitDetailsForm = (upsellValues, id, userDetails, paymentMethodId, options, tenant) => {
  const upsell = Object.values(upsellValues);

  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${getTenantDomain(tenant)}/api/iframe/submit-reservation`, {
        userDetails,
        upsell,
        paymentMethodId,
        options: {...options, arrivalDate: moment(options.arrivalDate).format('YYYY-MM-DD'),  departureDate: moment(options.departureDate).format('YYYY-MM-DD')},
        properyId: id,
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
