import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import VectorIcon from "../../../assets/images/Vector.svg"
import listIcon from "../../../assets/images/list.svg"
import Vector06Icon from "../../../assets/images/Vector06.svg"
import leftChevronIcon from "../../../assets/images/dropdown02.svg"
import rightChevronIcon from "../../../assets/images/dropdown03.svg"

import { useSelector, useDispatch } from "react-redux"
import { fetchProperties } from "../../../_actions/propertyActions.js"
import { fetchRequisites } from "../../../_actions/coreActions"

import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


import FilterTag from "./FilterTag"
import PropertyMap from "./Map"
import { Link } from "react-router-dom"
import {
  setActivePropertyId,
  setIsBlockView,
  setIsErrorDate,
  setPageNumber,
  setSelectedProperty,
  setTypes,
} from "../../../_actions/searchActions"
import SearchBar from "./SearchBar"
import PropertyImage from "./Gallery"
import { FaHome } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { getNameFromLocale } from "../../../helper"
import i18n from "../../../services/i18n"

export const tagsFilters = [
  {
    id: 3,
    name: "Apartmány",
    number: 24,
    icon: VectorIcon,
    isSelected: true,
  },
]

function LandingPage() {
  const dispatch = useDispatch()
  const params = useParams()
  const {
    isBlockView,
    mobileMenuOpen,
    arrivalDate,
    departureDate,
    activePropertyId,
    selectedProperty,
    pageNumber,
  } = useSelector((state) => state.search)

  const [totalNights, setTotalNights] = useState(0)

  // Function to compute total nights between two dates
  const getTotalNights = (start, end) => {
    const arrival = new Date(start)
    const departure = new Date(end)
    const diff = departure - arrival
    const totalNights = diff / (24 * 60 * 60 * 1000)
    return parseInt(totalNights)
  }
  const { t } = useTranslation()
  // Effect hook to update totalNights whenever arrivalDate or departureDate change
  useEffect(() => {
    setTotalNights(getTotalNights(arrivalDate, departureDate))
  }, [arrivalDate, departureDate])

  useEffect(() => {
    dispatch(fetchProperties(pageNumber, null, params.tenant))
  }, [dispatch, pageNumber])

  const facilities = useSelector((state) => state.core.facilities)
  useEffect(() => {
    dispatch(fetchRequisites(params.tenant))
  }, [dispatch])

  const properties = useSelector((state) => state.properties.properties)
  const types = useSelector((state) => state.core.types)
  useEffect(() => {
    if (departureDate < arrivalDate) {
      dispatch(setIsErrorDate(true))
    } else {
      dispatch(setIsErrorDate(false))
    }
  }, [arrivalDate, departureDate, dispatch])

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("filter-open")
    } else {
      document.body.classList.remove("filter-open")
    }
    return () => {
      document.body.classList.remove("filter-open")
    }
  }, [mobileMenuOpen])

  const regex = /(<([^>]+)>)/gi

  const increasePageNumber = (e) => {
    e.preventDefault()
    if (pageNumber === properties.last_page) return
    dispatch(setPageNumber(pageNumber + 1))
  }
  const decreasePageNumber = (e) => {
    e.preventDefault()
    if (pageNumber > 1) {
      dispatch(setPageNumber(pageNumber - 1))
    } else {
      dispatch(setPageNumber(1))
    }
  }

  if (!properties || properties.length === 0) {
    return <div>Loading...</div> // or any other loading indicator
  }

  return (
    <>
      <main>
        <Tooltip id="my-tooltip" />

        <section
          className={`${
            isBlockView ? "formPageMainSec" : "formPageMainSec mapStylesBlocks"
          }`}
        >
          <div className="container">
            <SearchBar />
            <ul className="listUnstyled selectTabList">
              {types
                ?.filter((tag) => tag.isSelected)
                .map((singleTag, index) => (
                  <FilterTag
                    number={singleTag.number}
                    text={singleTag.name}
                    icon={VectorIcon}
                    key={index}
                    isSelected={singleTag.isSelected}
                    onClick={() => {
                      dispatch(setTypes(singleTag.id))
                    }}
                  />
                ))}
            </ul>
            <div className="catListWrap">
              <div className="catAppListWrap">
                <ul className="catAppartList listUnstyled">
                  {types
                    ?.filter((tag) => !tag.isSelected)
                    .map((singleTag, index) => (
                      <FilterTag
                        number={singleTag.number}
                        text={singleTag.name}
                        icon={singleTag.icon}
                        key={index}
                        isSelected={singleTag.isSelected}
                        onClick={() => {
                          dispatch(setTypes(singleTag.id))
                        }}
                      />
                    ))}
                </ul>
              </div>
              <ul className="tabIcnList listUnstyled">
                <li
                  className="active"
                  onClick={() => dispatch(setIsBlockView(true))}
                >
                  <div className="listingView tabs">
                    <span className="homeIcn">
                      <img src={listIcon} alt="hone Icon" />
                    </span>
                  </div>
                </li>
                <li onClick={() => dispatch(setIsBlockView(false))}>
                  <div className="mapIcn tabs">
                    <span className="homeIcn">
                      <img src={Vector06Icon} alt="hone Icon" />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="appartmentListWrap">
              {properties.data.length === 0 && (
                <div className="no-property-container">
                  <div className="no-property-icon">
                    <FaHome />
                  </div>
                  <p className="no-property-text">{t("no_results")}</p>
                </div>
              )}
              <div className="colsHolder rowReverse">
                <div className="chCol chCol8 chCol8Alt">
                  <div className="mapImagewrap">
                    <div className="mapWrap">
                      {properties.data.length > 0 && (
                        <PropertyMap
                          selectedProperty={
                            selectedProperty
                              ? selectedProperty
                              : properties.data[0]
                          }
                          zoom={20}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="chCol chCol4 chCol4Alt">
                {properties.data.length > 0 && (
                  <div className="colsHolder blocksStructMain">
                    <div className="chCol100">
                      <span className="moreResultTxt">{t("more_results")}</span>
                    </div>
                    {properties.data.map((property) => (
                      <div
                        className="chCol chCol4"
                        key={property.id}
                        onClick={() => {
                          if (isBlockView) return
                          console.log(property)
                          dispatch(setSelectedProperty(property))
                        }}
                      >
                        <article
                          className={`${
                            property.id === activePropertyId
                              ? "appartInfoWrap featListActive"
                              : "appartInfoWrap"
                          }`}
                        >
                          <div className="appartImageHolder">
                            <div
                              className="dBlock"
                              style={{ height: "177px", width: "169px" }}
                            >
                              <PropertyImage
                                property={property}
                                images={property.gallery}
                                tenant={params.tenant}
                              />
                            </div>
                          </div>

                          <div
                            className="appatTextWrap"
                            style={{ width: "70%" }}
                          >
                            <h2 className="appartHeadingHead">
                              <Link
                                to={`/${params.tenant}/book/${property.id}`}
                              >
                                {property.name}
                              </Link>
                            </h2>
                            <p>
                              {/* {property.description.replace(regex, " ")}. */}
                              Apartmán sa nachádza blízko centra, k dispozícii
                              sú 2 parkovacie miesta.
                            </p>
                            <strong>Vybavenie apartmánu</strong>
                            <span
                              className="featureIcnListOpener"
                              onClick={() => {
                                if (activePropertyId === property.id) {
                                  dispatch(setActivePropertyId(null))
                                } else {
                                  dispatch(setActivePropertyId(property.id))
                                }
                              }}
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </span>
                            {activePropertyId === property.id && (
                              <ul className={`featureIcnList listUnstyled`}>
                              {property.facilities.length > 0 && facilities
                                ?.filter((facility) => () => {property.facilities?.include(facility.id)})
                               .map((facility) => (
                                <li key={facility.id}>
                                  <span className="Icn">
                                    <img
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={getNameFromLocale(facility.name, i18n.resolvedLanguage)}
                                      data-tooltip-place="top"
                                      alt={getNameFromLocale(facility.name, i18n.resolvedLanguage)}
                                      src={`https://admin.rentalls.com/uploads/${facility.icon}`}
                                    />
                                  </span>
                                </li>
                              ))}
                            </ul>
                            )}
                            
                            <div className="priceBtnWrap">
                              <span className="priceTag">
                                <b>{property.totalPrice} € /</b>
                                {totalNights} noci
                              </span>
                              <Link
                                to={`/${params.tenant}/book/${property.id}`}
                                className="btnPrimary"
                              >
                                Rezervovať
                              </Link>
                            </div>
                          </div>
                        </article>
                      </div>
                    ))}
                  </div>)}
                  {properties.data.length !== 0 && (
                    <ul className="pagiNation listUnstyled">
                      <li onClick={decreasePageNumber}>
                        <a href="#" className="prevIcn">
                          <img src={leftChevronIcon} alt="left Icn" />
                        </a>
                      </li>
                      {Array.from({ length: properties.last_page }, (_, i) => (
                        <li
                          key={i}
                          className={`${i + 1 === pageNumber ? "active" : ""}`}
                          onClick={(e) => {
                            e.preventDefault()
                            dispatch(setPageNumber(i + 1))
                          }}
                        >
                          {<a href="#">{i + 1}</a>}
                        </li>
                      ))}

                      <li onClick={increasePageNumber}>
                        <button href="#" className="NextIcn">
                          <img src={rightChevronIcon} alt="right Icn" />
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default LandingPage
