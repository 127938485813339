import moment from "moment"
import {
  ADD_CHILDREN_AGE,
  ARRIVAL_DROPDOWN_HANDLER,
  CITY_DROPDOWN_HANDLER,
  CLOSE_ALL_DROPDOWN,
  DECREASE_ADULTS,
  DECREASE_PETS,
  DEPARTURE_DROPDOWN_HANDLER,
  INCREASE_ADULTS,
  INCREASE_CHILDREN,
  INCREASE_PETS,
  PERSON_DROPDOWN_HANDLER,
  REMOVE_CHILDREN_AGE,
  SET_ACTIVE_PROPERTY_ID,
  SET_ARRIVAL_DATE,
  SET_DEPARTURE_DATE,
  SET_IS_ARRIVAL,
  SET_IS_BLOCK_VIEW,
  SET_IS_CITY_DROPDOWN,
  SET_IS_DEPARTURE,
  SET_IS_ERROR_DATE,
  SET_IS_MOBLE_OPEN,
  SET_IS_PERSON_DROPDOWN,
  SET_PAGE_NUMBER,
  SET_SELECTED_CITY,
  SET_SELECTED_PROPERTY,
  SET_TYPES,
} from "../_actions/types"

const initialState = {
  persons: {
    adults: 1,
    children: 0,
    ageOfChildren: [],
    pets: 0,
  },
  isBlockView: false,
  isCityDropdownActive: false,
  isPersonDropdownActive: false,
  mobileMenuOpen: false,
  isArrival: false,
  isDeparture: false,
  errorDate: false,
  arrivalDate: new Date(),
  departureDate: moment().add(1, "days").toDate(),
  activePropertyId: null,
  selectedProperty: null,
  cities: [],
  selectedCity: "",
  pageNumber: 1,
  types: [],
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_ADULTS:
      return {
        ...state,
        persons: { ...state.persons, adults: state.persons.adults + 1 },
      }
    case DECREASE_ADULTS:
      return {
        ...state,
        persons: { ...state.persons, adults: state.persons.adults - 1 },
      }
    case INCREASE_CHILDREN:
      return {
        ...state,
        persons: { ...state.persons, children: state.persons.children + 1 },
      }
    case INCREASE_PETS:
      return {
        ...state,
        persons: { ...state.persons, pets: state.persons.pets + 1 },
      }
    case DECREASE_PETS:
      return {
        ...state,
        persons: { ...state.persons, pets: state.persons.pets - 1 },
      }
    case ADD_CHILDREN_AGE:
      const { index, value } = action.payload
      const newAgeOfChildren = [...state.persons.ageOfChildren]
      newAgeOfChildren[index] = value
      return {
        ...state,
        persons: {
          ...state.persons,
          ageOfChildren: newAgeOfChildren,
        },
      }
    case REMOVE_CHILDREN_AGE:
      const newAgeOfChildren2 =
        state.persons.ageOfChildren.length > 0
          ? state.persons.ageOfChildren.slice(0, -1)
          : []
      return {
        ...state,
        persons: {
          ...state.persons,
          children: state.persons.children - 1,
          ageOfChildren: newAgeOfChildren2,
        },
      }
    case SET_IS_BLOCK_VIEW:
      return { ...state, isBlockView: action.payload }
    case SET_IS_CITY_DROPDOWN:
      return { ...state, isCityDropdownActive: action.payload }

    case SET_IS_PERSON_DROPDOWN:
      return { ...state, isPersonDropdownActive: action.payload }
    case SET_IS_MOBLE_OPEN:
      return { ...state, mobileMenuOpen: action.payload }
    case SET_IS_ARRIVAL:
      return { ...state, isArrival: action.payload }
    case SET_IS_DEPARTURE:
      return { ...state, isDeparture: action.payload }

    case SET_ARRIVAL_DATE:
      return { ...state, arrivalDate: action.payload }

    case SET_DEPARTURE_DATE:
      return { ...state, departureDate: action.payload }

    case SET_IS_ERROR_DATE:
      return { ...state, errorDate: action.payload }
    case SET_ACTIVE_PROPERTY_ID:
      return { ...state, activePropertyId: action.payload }
    case SET_SELECTED_PROPERTY:
      return { ...state, selectedProperty: action.payload }
    // case SET_CITIES:
    //   return { ...state, cities: action.payload }
    case SET_SELECTED_CITY:
      return { ...state, selectedCity: action.payload }
    case SET_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload }

    case SET_TYPES:
      const newTypes = state.types.map((type) =>
        type.id === action.payload
          ? { ...type, isSelected: !type.isSelected }
          : type
      )
      return { ...state, types: newTypes }

    case ARRIVAL_DROPDOWN_HANDLER:
      return {
        ...state,
        isArrival: true,
        isDeparture: false,
        isCityDropdownActive: false,
        isPersonDropdownActive: false,
      }
    case DEPARTURE_DROPDOWN_HANDLER:
      return {
        ...state,
        isArrival: false,
        isDeparture: true,
        isCityDropdownActive: false,
        isPersonDropdownActive: false,
      }
    case CITY_DROPDOWN_HANDLER:
      return {
        ...state,
        isArrival: false,
        isDeparture: false,
        isCityDropdownActive: true,
        isPersonDropdownActive: false,
      }
    case PERSON_DROPDOWN_HANDLER:
      return {
        ...state,
        isArrival: false,
        isDeparture: false,
        isCityDropdownActive: false,
        isPersonDropdownActive: !state.isPersonDropdownActive,
      }
    case CLOSE_ALL_DROPDOWN:
      return {
        ...state,
        isArrival: false,
        isDeparture: false,
        isCityDropdownActive: false,
        isPersonDropdownActive: false,
      }
    default:
      return state
  }
}

export default searchReducer
